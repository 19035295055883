import React from 'react';
import { Link } from 'gatsby';

const VolverBtn = props => {
  return (
    <Link className="volver-btn" to={props.to}>
      <img src="/img/boton_bajar_tarea.svg" />
      Volver
    </Link>
  );
};
export default VolverBtn;
