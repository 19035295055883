import { useCallback, useState } from 'react';
import { navigate } from 'gatsby';

import { api } from '../services';

/**
 * Hook que contiene la logica del formulario de recuperacion de contrasenia
 * (segunda parte)
 * @param {String} code
 */
const usePasswordRecoveryForm = (code, currentLang, label) => {
  const [inputs, setInputs] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { mutationPasswordRecovery } = api();

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = useCallback(
    event => {
      if (event) {
        event.preventDefault();
      }
      const submit = async () => {
        setLoading(true);
        try {
          const res = await mutationPasswordRecovery({
            password: inputs.password,
            passwordConfirmation: inputs.passwordConfirmation,
            code: code,
            lang: currentLang,
          });
          console.debug('res', res);
          if (res.resetPassword) {
            navigate('/login');
            return false;
          } else setMessage(label ? label.messageFail : 'messageFail');
        } catch (error) {
          setMessage(label ? label.messageFail : 'messageFail');
        } finally {
          setLoading(false);
        }
      };
      if (inputs.password !== inputs.passwordConfirmation) {
        setMessage(label ? label.diffPassLabel : 'diffPassLabel');
      } else if (
        inputs.password.search(
          label && label.passRegExp
            ? new RegExp(label.passRegExp)
            : /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i
        ) < 0
      ) {
        setMessage(label ? label.rulePassLabel : 'rulePassLabel');
      } else {
        submit();
      }
    },
    [
      code,
      inputs.password,
      inputs.passwordConfirmation,
      mutationPasswordRecovery,
    ]
  );

  return {
    handleSubmit,
    handleInputChange,
    message,
    inputs,
    loading,
  };
};

export default usePasswordRecoveryForm;
