import React, { useEffect } from 'react';
import simpleheat from 'simpleheat';

const Heatmap = props => {
  // const canvasRef = React.useRef()
  // let heat = simpleheat('canvasRef');

  useEffect(() => {
    // console.debug('generando hetmap 0', props.data);
    if (props.visible && props.data && props.data.length > 0) {
      // console.debug('generando hetmap');
      const heat = simpleheat('canvas-heatmap');
      heat.clear();
      try {
        // setTimeout(()=>{
        heat.resize();
        // console.debug('generando hetmap 2', props.data);

        heat.data(props.data);
        // props.data.map(point => heat.add(point))
        heat.radius(10, 15);
        heat.max(parseInt(props.data.length / 10));
        heat.draw(0.25);

        // }, 1000)
      } catch (error) {
        console.error('Problemas al generar el heatmap', error);
      }
    }
  }, [props.data, props.visible]);

  return (
    <canvas
      width={props.style.width}
      height={props.style.height}
      id="canvas-heatmap"
      // ref={canvasRef}
      style={props.style}
      className={props.className}
    />
  );
};

export default Heatmap;
