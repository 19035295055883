import { useCallback, useState } from 'react';

import { api } from '../services';

/**
 * Hook que contiene la logica del formulario del registro de usuario
 *
 */
const useContactForm = ({ labels, currentLang }) => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    messageContact: '',
    limit: 500,
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifiyCaptcha, setVerifiyCaptcha] = useState(false);
  const [token, setToken] = useState('');
  const [sendOk, setSendOk] = useState(false);
  const { sendContactMessage } = api();

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]:
        event.target.name === 'messageContact'
          ? event.target.value.substring(0, inputs.limit)
          : event.target.value,
    }));
  };

  const handleSubmit = useCallback(
    event => {
      if (event) {
        event.preventDefault();
      }
      // validación de formulario
      if (!verifiyCaptcha && false) {
        // console.debug('No hay captcha válido');
        // return false;
      }
      const submit = async () => {
        setMessage('');
        setError('');
        setLoading(true);
        try {
          const response = await sendContactMessage({
            name: inputs.name,
            email: inputs.email,
            messageContact: inputs.messageContact,
            lang: currentLang,
          });
          if (response.ok) {
            setMessage(labels.sendOkMessage || 'sendOkMessage');
            setSendOk(true);
          } else setError(labels.sendErrorMessage || 'sendErrorMessage');
        } catch (error) {
          setError(labels.sendErrorMessage || 'sendErrorMessage');
        } finally {
          setLoading(false);
        }
      };
      if (inputs.email.search(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/) < 0)
        setError(labels.sendErrorEmailMessage || 'sendErrorEmailMessage');
      else if (verifiyCaptcha) submit();
    },
    [inputs.name, inputs.email, inputs.messageContact, sendContactMessage]
  );

  const handleVerifyCaptcha = useCallback(
    token => {
      // console.debug('token', token)
      setVerifiyCaptcha(true);
      setToken(token);
      console.debug('token', token);
    },
    [setVerifiyCaptcha, setToken]
  );

  const tokenVerify = token => {
    setVerifiyCaptcha(true);
    setToken(token);
    console.debug('token', token);
  };

  return {
    handleSubmit,
    handleInputChange,
    handleVerifyCaptcha,
    setVerifiyCaptcha,
    message,
    inputs,
    error,
    loading,
    sendOk,
  };
};

export default useContactForm;
