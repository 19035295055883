import { useCallback, useState } from 'react';

import { api } from '../services';

/**
 * Hook que contiene la logica del formulario del registro de usuario
 *
 */
const useCheckInForm = ({ labels, currentLang }) => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifiyCaptcha, setVerifiyCaptcha] = useState(false);
  const [token, setToken] = useState('');
  const [registerOk, setRegisterOk] = useState(false);
  const { registerNewUser } = api();

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = useCallback(
    event => {
      setMessage('');
      setError('');
      setLoading(true);
      if (event) {
        event.preventDefault();
      }
      // validación de formulario
      if (!verifiyCaptcha && false) {
        // console.debug('No hay captcha válido');
        // return false;
      }
      const submit = async () => {
        try {
          const response = await registerNewUser({
            name: inputs.name,
            email: inputs.email,
            lang: currentLang,
          });
          if (response.ok) {
            setMessage(labels.registerOkCheckIn || 'registerOkCheckIn');
            setRegisterOk(true);
          } else setError(labels[response.message] || response.message);
        } catch (error) {
          setError(labels.errorCheckIn || 'errorCheckIn');
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [inputs.name, inputs.email, registerNewUser]
  );

  const handleVerifyCaptcha = useCallback(
    token => {
      // console.debug('token', token)
      setVerifiyCaptcha(true);
      setToken(token);
    },
    [setVerifiyCaptcha, setToken]
  );

  return {
    handleSubmit,
    handleInputChange,
    handleVerifyCaptcha,
    message,
    inputs,
    error,
    loading,
    registerOk,
  };
};

export default useCheckInForm;
