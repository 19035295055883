import { useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

/**
 * Hook que revisa si es la primera vez que el usuario entra en la pagina.
 * y lo reenvia a la raiz
 */
const useCheckFirstVisit = () => {
  const isFirstTime = useSelector(({ config }) => config.initialVisit);
  const isLenguajeSelected = useSelector(({ config }) => config.language);
  const location = useLocation();
  const query = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(Array.from(query)) || {};

  const code = params.code || null;

  if (isFirstTime && !code && !isLenguajeSelected) {
    navigate('/');
  }
};

export default useCheckFirstVisit;
