import { navigate } from 'gatsby';
import { useCallback, useState } from 'react';

import { api } from '../services';

/**
 * Hook que contiene la logica del formulario para recuperacion de contrasenia
 * (primer paso)
 *
 * @param {Strign} messages
 * @param {Function} toggleModal
 */
const useForgotPasswordForm = (messages, toggleModal, currentLang) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { mutationForgotPassword } = api();

  const emailHandler = event => {
    event.persist();
    setEmail(event.target.value);
  };

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();
    }
    const submit = async () => {
      try {
        const response = await mutationForgotPassword(email, currentLang);
        if (response) {
          setMessage(messages.ok);
        } else {
          setMessage(messages.fail);
        }
        setTimeout(() => {
          navigate('/login');
          toggleModal();
          setMessage('');
          setEmail('');
        }, 5000);
      } catch (error) {
        setMessage(messages.fail);
      }
    };
    submit();
  });

  return { emailHandler, handleSubmit, email, message };
};

export default useForgotPasswordForm;
