import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../services';

/**
 * Hook que contiene la logica para la creacion del heatmap
 * para la pagina asociada
 * @param {window.location} location
 */
const useHeatmap = location => {
  const heatmapView = useSelector(({ login }) => login.user.heatmap);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [dataHeatmap, setDataHeatmap] = useState(null);
  const { getHeatmapsLocation } = api();

  const isVertical = (
    width = window.screen.width,
    height = window.screen.height
  ) => {
    return width < height;
  };

  useEffect(() => {
    let ignore = false;
    const getHeatMap = async location => {
      setLoading(true);
      const response = await getHeatmapsLocation(location);
      // console.debug("respuesta heatmap:", response);
      const data = [];
      response.forEach(heat => {
        // verifica si las dimesiones conciden a pantallas horizontales o verticales
        if (isVertical() == isVertical(heat.window_width, heat.window_height))
          data.push([
            parseInt(
              (((heat.x * 100) / heat.window_width) * window.screen.width) / 100
            ),
            parseInt(
              (((heat.y * 100) / heat.window_height) * window.screen.height) /
                100
            ),
            1,
          ]);
      });
      // console.debug("datos2", data);
      setDataHeatmap(data);
      setLoading(false);
    };
    if (location && active) {
      try {
        if (!ignore) {
          getHeatMap(location);
        }
      } catch (error) {
        setLoading(false);
        console.error('Problemas al procesar el heatmap', error);
      }
    }
    return () => {
      ignore = true;
    };
  }, [location, active]);

  useEffect(() => {
    // heatmap_view acivado en la administración
    if (heatmapView) setActive(true);
  }, [heatmapView]);

  const onChange = useCallback(event => {
    // console.debug('heatmap event:', event.target.checked);
    setVisible(event.target.checked);
    if (event.target.checked) {
      setHeight(document.body.clientHeight);
      setWidth(document.body.clientWidth);
    } else {
      setTimeout(() => {
        setHeight(0);
        setWidth(0);
      }, 500);
    }
  });

  return { onChange, active, loading, visible, dataHeatmap, height, width };
};

export default useHeatmap;
