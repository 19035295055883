import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { api } from '../services';

// import { updateRole } from '../store/actions/login';

const useUnsubscribe = ({ labels }) => {
  // const dispatch = useDispatch();

  const currentToken = useSelector(({ login }) => login.token);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  const { unsubscribePlan } = api();

  const handleSubmit = useCallback(
    event => {
      setLoading(true);
      setMessage('');
      if (event) {
        event.preventDefault();
      }
      const submit = async () => {
        try {
          const { data } = await unsubscribePlan(currentToken);
          console.debug(data);
          if (data.ok) {
            setMessage(labels.unsubscribeCorrectPlan || 'NULL');
            setOk(true);
          } else setMessage(labels.unsubscribeIncorrectPlan || 'NULL');
        } catch (error) {
          setMessage(labels.unsubscribeProblemPlan || 'NULL');
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [unsubscribePlan]
  );

  return {
    handleSubmit,
    loading,
    message,
    ok,
  };
};

export default useUnsubscribe;
