import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

/**
 * Hook que contiene la logica de redireccion para la pagina
 * etapa.
 *
 * @param {String?} stepId
 * @param {String?} taskId
 */
const useRedirectStep = (stepId, taskId) => {
  const stepStore = useSelector(store => store.step);

  useEffect(() => {
    // Check if step is unlock
    // steps unlocks
    const unlockedSteps = stepStore.unlockedSteps.map(step => step.stepId);
    // tasks unlocks
    const unlockedTasks = stepStore.unlockedSteps
      .map(step => step.tasks)
      .flat();

    const isStepUnlock = unlockedSteps.includes(parseInt(stepId, 10));
    const isTaskUnlock = unlockedTasks.includes(parseInt(taskId, 10));
    if (!isStepUnlock && isTaskUnlock) {
      const lastStep = Math.max(...unlockedSteps);
      const lastTask = Math.max(...unlockedTasks);
      navigate(`/etapa/${lastStep}/tarea/${lastTask}`);
    }
  }, [stepId, taskId]);
};

export default useRedirectStep;
