import { useEffect, useCallback, useState } from 'react';
import { api } from '../services';

/**
 * Hook que contiene la logica de comportamiento del mouse
 */
const useCaptureMouse = () => {
  const [saveActive, setSaveActive] = useState(true);
  const { insertHeatMapData } = api();
  let active = true;

  const position = e => {
    const href = window.location.href;
    let posx = 0;
    let posy = 0;
    const width = window.screen.width;
    const height = window.screen.height;

    if (!e) {
      var e = window.event;
    }
    if (e.type === 'touchmove') {
      posx = e.touches[0].pageX;
      posy = e.touches[0].pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { href, posx, posy, width, height };
  };

  const onClick = useCallback(e => {
    const { href, posx, posy, width, height } = position(e);
    // console.debug('se navega con click: ', href, posx, posy);
    insertHeatMapData({
      x: posx,
      y: posy,
      ip: '127.0.0.1',
      content: href,
      user_agent: window.navigator.userAgent,
      window_width: width,
      window_height: height,
      action: 'click',
    });
  });

  const onMouseMove = e => {
    e.persist();
    // console.debug('move ', e.type);
    if (saveActive && (e.type === 'touchmove' || e.type === 'mousemove')) {
      setSaveActive(false);
      const { href, posx, posy, width, height } = position(e);
      // console.debug('se navega con move: ', href, posx, posy);
      insertHeatMapData({
        x: posx,
        y: posy,
        ip: '127.0.0.1',
        content: href,
        user_agent: window.navigator.userAgent,
        window_width: width,
        window_height: height,
        action: e.type === 'touchmove' ? 'touch' : 'move',
      });
      setTimeout(() => {
        setSaveActive(true);
      }, 2000);
    }
  };

  return { onClick, onMouseMove };
};

export default useCaptureMouse;
