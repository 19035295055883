import React, { useCallback, useState, useEffect } from 'react';

import CommentAdd from './CommentAdd';
import CommentList from './CommentList';

import { api } from '../../services';
import { useSelector } from 'react-redux';

const CommentGroup = () => {
  const [comment, setComment] = useState('');
  const [commented, setCommented] = useState(false);
  const [contenido, setContenido] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLabels, setLoadingLabels] = useState(true);
  const [labels, setLabels] = useState({});
  const [listComments, setListComments] = useState([]);
  const { addComment, getComments, fetchLabelsComments, isPlanActive } = api();
  const currentToken = useSelector(({ login }) => login.token);
  const currentLang = useSelector(({ config }) => config.language);
  const isLogged = useSelector(({ login }) => login.isLogged);
  //Comment Add
  const handleCommentSubmit = useCallback(async comentario => {
    setLoading(true);
    try {
      if (comentario) {
        const { resultAddComment } = await addComment(
          currentToken,
          comentario,
          contenido
        );
        console.log(resultAddComment);

        //Se limpia Comment
        setComment('');
        const { data } = await getComments(contenido);
        setListComments(data.comentarios);
        setCommented(true);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  });
  //Comment List
  const renderComments = useCallback(async contenido => {
    const { data } = await getComments(contenido);
    setListComments(data.comentarios);
  });
  //Comment List
  const renderLabels = useCallback(async contenido => {
    const { labels } = await fetchLabelsComments(currentLang);
    setLabels(labels);
    setLoadingLabels(false);
  });

  const fetchData = useCallback(async () => {
    {
      const result = await isPlanActive(currentToken);
      setIsActive(result);
      return result;
    }
  });

  useEffect(() => {
    if (isLogged) {
      fetchData();
    }
  }, [isActive, isLogged]);

  useEffect(() => {
    if (contenido != '') {
      renderComments(contenido);
    }
    if (Object.keys(labels).length === 0) {
      renderLabels();
    }
  }, [contenido]);
  //Use effect para asociar URL en variable contenido
  useEffect(() => {
    setContenido(window.location.href);
  }, []);

  return (
    contenido &&
    !loadingLabels && (
      <div className="container comentarios-group">
        <h4>{labels.titleComment || 'NULL'}</h4>
        <div>
          <CommentList listComments={listComments} loading={loading} />
        </div>
        {isLogged && isActive ? (
          !commented ? (
            <CommentAdd
              handleCommentSubmit={handleCommentSubmit}
              setComment={setComment}
              comment={comment}
              loading={loading}
              labels={labels}
            />
          ) : (
            <div className="alert alert-info">
              <i className="fas fa-thumbs-up"></i>{' '}
              {labels.userCommented || 'NULL'}
            </div>
          )
        ) : (
          <div className="alert alert-warning">
            <i className="fas fa-exclamation-triangle"></i>{' '}
            {labels.notLoggin || 'NULL'}
          </div>
        )}
      </div>
    )
  );
};

export default CommentGroup;
