import React from 'react';
import { CustomInput } from 'reactstrap';

import { useHeatmap } from '../../hooks';

import Heatmap from './Heatmap';

const Container = ({ location }) => {
  const {
    active,
    loading,
    visible,
    dataHeatmap,
    height,
    width,
    onChange,
  } = useHeatmap(location);
  return active ? (
    <div className="defi-heatmap">
      <div className="defi-heatmap-selector">
        <CustomInput
          type="switch"
          label=""
          id="defi-heatmap-switch"
          onChange={onChange}
        />
      </div>
      <Heatmap
        className={`defi-heatmap-canvas ${visible ? 'active' : 'inactive'}`}
        style={{ height: height, width: width }}
        data={dataHeatmap}
        loading={loading}
        visible={visible}
      />
    </div>
  ) : (
    ''
  );
};

export default Container;
