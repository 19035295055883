import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

/**
 * Hook que contiene la logica para la redireccion
 * de la tarea en particular
 * @param {String?} id
 */
const useRedirectTask = id => {
  const stepStore = useSelector(({ step }) => step);
  // Array of task unlocks
  const unlockedTasks = stepStore.unlockedSteps
    .map(step => step.tasks)
    .reduce((obj, val) => [...obj, ...val], []);

  useEffect(() => {
    // Check if step is unlock
    const isUnlocked = unlockedTasks.includes(parseInt(id, 10));
    if (!isUnlocked) {
      const lastTask = Math.max(...unlockedTasks);
      navigate(`/tarea/${lastTask}`);
    }
  }, [id]);
};

export default useRedirectTask;
