import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { Heatmap } from '../components/heatmap';

import { useCheckFirstVisit, useCaptureMouse } from '../hooks';

import ReactGA from 'react-ga4';
//Actualizar a GA4 https://www.npmjs.com/package/react-ga4

// ReactGA.initialize('G-8TRZ99ECY9'); // Código Dev G4
// ReactGA.initialize('UA-183836633-1'); // Código UA
ReactGA.initialize('G-79LTQTWQZV'); // Código Producción
ReactGA.send('pageview');

const BaseLayout = ({ title, children }) => {
  // ReactGA.send({
  //   hitType: 'pageview',
  //   page: window.location.pathname,
  //   title: 'Pageview',
  // });

  const { onMouseMove, onClick } = useCaptureMouse();
  const location = useLocation();
  // console.debug('location path', location);
  useCheckFirstVisit();

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        <meta name="description" content="Descripción" />
        <meta name="author" content="CaWot" />
      </Helmet>
      <div
        key="capsule"
        onMouseDown={onClick}
        onMouseMove={onMouseMove}
        onTouchMove={onMouseMove}
      >
        {children}
      </div>
      <div>
        <Heatmap location={location.host + location.pathname}></Heatmap>
      </div>
    </>
  );
};

export default BaseLayout;
