import React, { Fragment } from 'react';
import MarkdownView from 'react-showdown';
import { CommentGroup } from '../comments';
import VolverBtn from './VolverBtn';

const Content = ({ title, content }) => {
  return (
    <Fragment>
      <div className="row position-relative justify-content-center m-0">
        <div className="static-content col-12 col-md-10">
          <VolverBtn to="/menu" />
          <h2 className="static-content-title">{title}</h2>
          <MarkdownView
            markdown={content}
            className="static-content-container"
          />
          <CommentGroup />
        </div>
      </div>
    </Fragment>
  );
};

export default Content;
