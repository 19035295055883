import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/es';

const CommentList = prop => {
  console.log(prop);
  return (
    <div className="comentarios-list">
      {prop.listComments.map(item => (
        <div key={item.id} className="comentario-item">
          <h5 className={`comentario-title`}>
            <span className={`${item.front_admin ? 'comentario-admin' : ''}`}>
              {item.usuario}
            </span>
          </h5>
          <span className="comentarios-date">
            <Moment
              format="DD [de] MMMM [de] YYYY [a las] HH:MM[hrs]"
              locale="ES"
            >
              {item.fecha}
            </Moment>
          </span>
          <p className="comentarios-text">{item.comentario}</p>
        </div>
      ))}
    </div>
  );
};

export default CommentList;
