import { useLocation } from '@reach/router';

/**
 * Hook que contiene la logica de obtencion
 * de datos via query string para el modal
 * recuperar contrasenia
 */
const useForgotPasswordParam = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(Array.from(query)) || {};

  return Boolean(params.passwordRecovery) || false;
};

export default useForgotPasswordParam;
