import React from 'react';
const CommentAdd = prop => {
  return (
    <div className="form-group comment-add">
      <textarea
        className="form-control comment-textarea"
        rows="3"
        name="comment"
        id="comment"
        onChange={event => prop.setComment(event.target.value)}
        value={prop.comment}
        disabled={prop.loading}
        placeholder={prop.labels.placeholderComment || ''}
      ></textarea>
      {!prop.loading && (
        <button
          className="btn-plan-yellow comment-btn"
          onClick={() => prop.handleCommentSubmit(prop.comment)}
        >
          {prop.labels.buttonAddComment || 'NULL'}
        </button>
      )}
    </div>
  );
};

export default CommentAdd;
