import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import { api } from '../services';

/**
 * Hook que contiene la logica del formulario de recuperacion de contrasenia
 * (segunda parte)
 * @param {String} code
 */
const usePlanPay = labels => {
  const currentToken = useSelector(({ login }) => login.token);
  const [inputs, setInputs] = useState({
    plan: null,
    method: null,
  });
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const { restPaySelected } = api();

  const handleSubmit = useCallback(
    event => {
      setMessage('');
      if (event) {
        event.preventDefault();
      }
      if (!inputs.method) {
        setMessage(labels.selectMethodPayment || 'NULL');
        return false;
      }
      const submit = async () => {
        setLoadingPayment(true);
        try {
          const { data } = await restPaySelected(
            currentToken,
            inputs.plan.id,
            inputs.method
          );
          // console.debug('url', data);

          if (data.url) {
            setUrl(data.url);
            if (inputs.method === 'flow') {
              window.location.href = data.url;
            } else {
              setTimeout(() => {
                navigate(data.url);
              }, 2000);
            }
          } else {
            setMessage(labels.problemRedirectPayment || 'NULL');
          }
          // navigate('/login');
        } catch (error) {
          setMessage(labels.errorPayment || 'NULL');
        } finally {
          setLoadingPayment(false);
        }
      };
      submit();
    },
    [inputs.plan, inputs.method, restPaySelected]
  );

  const handlePaypal = sub_id => {
    setMessage('');
    if (!inputs.method) {
      setMessage(labels.selectMethodPayment || 'NULL');
      return false;
    }
    const submit = async () => {
      setLoadingPayment(true);
      try {
        const { data } = await restPaySelected(
          currentToken,
          inputs.plan.id,
          inputs.method,
          sub_id
        );
        // console.debug('url', data);

        if (data.url) {
          setUrl(data.url);
          if (inputs.method === 'flow') {
            window.location.href = data.url;
          } else {
            setMessage(labels.loadingStatusPayment || 'NULL');
            setLoadingPayment(false);
            setTimeout(() => {
              navigate(data.url);
            }, 1000);
          }
        } else {
          setMessage(labels.problemRedirectPayment || 'NULL');
        }
        // navigate('/login');
      } catch (error) {
        setMessage(labels.errorPayment || 'NULL');
      } finally {
        setLoadingPayment(false);
      }
    };
    submit();
  };

  const handleSelected = plan => {
    setMessage('');
    setLoadingPayment(false);
    setInputs({ ...inputs, plan: plan, method: null });
  };

  const handleMethod = event => {
    event.persist();
    if (event.target.value) setMessage('');
    console.debug('metodo', event.target.value);
    setInputs({ ...inputs, method: event.target.value });
    setLoadingPayment(false);
  };

  return {
    handlePaypal,
    handleSubmit,
    handleSelected,
    handleMethod,
    message,
    inputs,
    url,
    loadingPayment,
  };
};

export default usePlanPay;
