import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../services';

const useMyPassword = ({ labels }) => {
  const currentToken = useSelector(({ login }) => login.token);

  const [inputs, setInputs] = useState({
    password: '',
    newPassword: '',
    newPasswordRepeat: '',
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { updatePasswordProfile } = api();

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = useCallback(
    event => {
      setLoading(true);
      setMessage('');
      if (event) {
        event.preventDefault();
      }
      const submit = async () => {
        try {
          const { data } = await updatePasswordProfile(
            currentToken,
            inputs.password,
            inputs.newPassword
          );
          if (!data.ok) {
            console.debug(data);
            setMessage(labels[data.message] || 'NULL');
          }
          // navigate('/login');
        } catch (error) {
          setMessage(labels.problemPasswordMessageProfile);
        } finally {
          setLoading(false);
        }
      };

      if (inputs.newPassword !== inputs.newPasswordRepeat) {
        // differentPasswordMessageProfile
        setMessage(labels['differentPasswordMessageProfile'] || 'NULL');
        setLoading(false);
      } else if (
        inputs.newPassword.length < 6 ||
        inputs.newPassword.length > 10
      ) {
        // invalidPasswordMessageProfile
        setMessage(labels['invalidPasswordMessageProfile'] || 'NULL');
        setLoading(false);
      } else {
        submit();
      }
    },
    [
      inputs.password,
      inputs.newPassword,
      inputs.newPasswordRepeat,
      updatePasswordProfile,
    ]
  );

  return {
    handleSubmit,
    handleInputChange,
    message,
    inputs,
    loading,
  };
};

export default useMyPassword;
