import { useLocation } from '@reach/router';

/**
 * Hook que contiene la logica de obtencion
 * de datos via query string para la recuperacion
 * de contrasenia, especificamente retorna el codigo
 * que se envia via correo.
 */
const usePasswordRecoveryParam = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(Array.from(query)) || {};

  return params.code || '';
};

export default usePasswordRecoveryParam;
