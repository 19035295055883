import { useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../services';

const useMyData = ({ data, labels }) => {
  const currentToken = useSelector(({ login }) => login.token);

  const [inputs, setInputs] = useState({
    name: '',
    email: '',
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateMyCountData } = api();

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = useCallback(
    event => {
      setLoading(true);
      setMessage('');
      if (event) {
        event.preventDefault();
      }
      const submit = async () => {
        try {
          const { data } = await updateMyCountData(
            currentToken,
            inputs.email,
            inputs.name
          );
          if (!data.ok) {
            console.debug(data);
            setMessage(labels.problemSaveMessageProfile || 'NULL');
          }
          // navigate('/login');
        } catch (error) {
          setMessage(labels.problemSaveMessageProfile || 'NULL');
        } finally {
          setLoading(false);
        }
      };
      if (!inputs.name || inputs.name.length > 50) {
        // invalidPasswordMessageProfile
        setMessage(labels['invalidNameMessageProfile'] || 'NULL');
        setLoading(false);
      } else {
        submit();
      }
    },
    [inputs.email, inputs.name, updateMyCountData]
  );

  useEffect(() => {
    if (data)
      setInputs({
        name: data.user.name,
        email: data.email,
      });
  }, [data]);

  return {
    handleSubmit,
    handleInputChange,
    message,
    inputs,
    loading,
  };
};

export default useMyData;
