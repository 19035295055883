import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '../services';

/**
 * hook para agregar un email a la lista de grupo de la cuenta
 */
const useAddUserGroup = ({ setOpen, setSearchData }) => {
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);
  const [email, setEmail] = useState('');
  const [loadingHook, setLoadingHook] = useState(false);
  const [message, setMessage] = useState(null);
  const { addGroupMembers } = api();

  const handleSubmit = useCallback(
    event => {
      setMessage('');
      setLoadingHook(true);
      if (event) {
        event.preventDefault();
      }
      if (email === '') {
        setMessage('se debe ingresar el email');
        return false;
      }
      const submit = async () => {
        setLoadingHook(true);
        try {
          const { data } = await addGroupMembers(
            currentToken,
            currentLang,
            email
          );
          console.debug('data adduser', data);
          setSearchData(true);
          setOpen(false);
        } catch (error) {
          setMessage('Error al guardar usuario');
        } finally {
          setLoadingHook(false);
        }
      };
      submit();
    },
    [email, addGroupMembers]
  );

  const handleChange = event => {
    event.persist();
    if (event.target.value) setMessage(null);
    setEmail(event.target.value);
    setMessage('');
  };

  return {
    handleSubmit,
    handleChange,
    message,
    email,
    loadingHook,
  };
};

export default useAddUserGroup;
