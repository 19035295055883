import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import { logIn } from '../store/actions/login';
import { initUnlockedSteps } from '../store/actions/step';
import { api } from '../services';

/**
 * Hook que contiene la logica del formulario de inicio de sesion
 */
const useLoginForm = () => {
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const { mutationLogin } = api();

  const handleSubmit = useCallback(event => {
    if (event) {
      event.preventDefault();
    }
    const submit = async () => {
      try {
        const response = await mutationLogin({
          credentials: { email: inputs.email, password: inputs.password },
        });
        dispatch(
          logIn(response.jwt, {
            username: response.user.username,
            email: response.user.email,
            role: response.user.role,
            roleName: response.user.roleName,
            comment: response.user.comment,
            heatmap: response.user.heatmap,
          })
        );
        dispatch(initUnlockedSteps(response.user.progress));
        navigate('/etapa', { state: { prevPath: '/menu' } });
      } catch (error) {
        setShowError(true);
      }
    };
    submit();
  });

  const handleInputChange = useCallback(event => {
    event.persist();
    setShowError(false);
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  });

  return {
    handleSubmit,
    handleInputChange,
    showError,
    inputs,
  };
};

export default useLoginForm;
